import React, { useState, useContext, Fragment, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment, IconButton, Tooltip, FormControlLabel, Switch } from "@material-ui/core";
import StyledButton from "../common/StyledButton.js";
import Spinner from "../common/Spinner.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import UserContext from "../../UserContext.js";
import ActivityReport from "../../containers/activities/ActivityReport.js";
import { logAction } from "../../log.js";
import { useLocation } from "react-router-dom";
import { groupByDate, applyTextFilter } from "../../containers/activities/FilterData.js";

const useStyles = makeStyles((theme) => ({
  includeImages: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
}));

const ExportToPdf = ({ students, categories, projects, filterIndex, filters, filterText, sortMethod = "date" }) => {
  const classes = useStyles();
  const location = useLocation();
  const { country } = useContext(UserContext);
  const locale = country === "US" ? "en-us" : "en-au";
  const now = DateTime.local().toLocaleString({ month: "long", day: "numeric", locale: locale });
  const defaultExportFileName = "ActivityList_" + now.replace(/\s+/g, "");
  const [exportFileName, setExportFileName] = useState(defaultExportFileName);
  const [includeImages, setIncludeImages] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [activitiesByDate, setActivitiesByDate] = useState({});
  const [showActivityReport, setShowActivityReport] = useState(false);

  const updateActivitiesByDate = useCallback((filteredActivities) => {
    const sortComparison = (a, b) => {
      if (sortMethod === "dateCompleted") {
        a = new Date(a.dateCompleted);
        b = new Date(b.dateCompleted);
      } else {
        a = new Date(a.date);
        b = new Date(b.date);
      }
      return a - b;
    }
  
    const activities = filteredActivities.sort(sortComparison);
    const groupedActivities = groupByDate(activities, sortMethod, locale);
    if (filterText.length >= 2) {
      setActivitiesByDate(applyTextFilter(groupedActivities, filterText));
    } else {
      setActivitiesByDate(groupedActivities);
    }
  }, [filterText, locale, sortMethod]);
  
  useEffect(() => {
    const filteredActivities = filters[filterIndex];
    updateActivitiesByDate(filteredActivities);
  }, [filters, filterIndex, filterText, updateActivitiesByDate]);

  const handleCancelClick = () => {
    setShowPdf(false);
    setExportFileName(defaultExportFileName);
    setIncludeImages(false);
  };

  const handleDownloadClick = () => {
    logAction("Downloaded PDF file", country, location.pathname, { includeImages: includeImages });
    setShowPdf(false);
    setDownloading(true);
    setShowActivityReport(true);
  };

  return (
    <Fragment>
      <Tooltip title="Export to PDF">
        <IconButton
          onClick={() => {
            logAction("Clicked Export to PDF button", country, location.pathname); //Mixpanel logging
            setShowPdf(true);
          }}
        >
          <FontAwesomeIcon icon="file-download" />
        </IconButton>
      </Tooltip>
      <Dialog open={showPdf} onClose={() => handleCancelClick()}>
        {!showPdf ? (
          ""
        ) : (
          <Fragment>
            <DialogTitle>PDF</DialogTitle>
            <DialogContent>
              <TextField
                label="File Name"
                defaultValue={defaultExportFileName}
                onChange={(e) => setExportFileName(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">.pdf</InputAdornment>,
                }}
              ></TextField>
              <div className={classes.includeImages}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeImages}
                      onChange={() => {
                        includeImages ? setIncludeImages(false) : setIncludeImages(true);
                      }}
                      name="includeImages"
                    />
                  }
                  label="Include Image(s)"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <StyledButton onClick={() => handleCancelClick()}>Cancel</StyledButton>
              <StyledButton onClick={() => handleDownloadClick()}>Download</StyledButton>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
      <Spinner open={downloading} />
      {showActivityReport && (
        <ActivityReport
          activitiesByDate={activitiesByDate}
          students={students}
          categories={categories}
          projects={projects}
          exportFileName={exportFileName}
          includeImages={includeImages}
          country={country}
          setDownloading={setDownloading}
          setShowActivityReport={setShowActivityReport}
        />
      )}
    </Fragment>
  );
}

export default ExportToPdf;