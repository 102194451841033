import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography, Chip, Avatar, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
        },
    },
    leftColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    projectPlanLogo: {
        height: 250,
        borderRadius: theme.shape.borderRadius, // Rounded corners
        boxShadow: theme.shadows[5], // Dropshadow
        display: "block"
    },
    categoryChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.shade[100],
        color: theme.palette.grey[900],
    },
    tagChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
        fontStyle: "italic"
    },
    summary: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2)
    },
    table: {
        width: "auto"
    },
    purchaseButton: {
        marginTop: theme.spacing(2),
        flexGrow: 0,
        background: 'linear-gradient(135deg, rgba(219,90,107,1) 0%, rgba(245,143,132,1) 100%)', //#db5a6b Gradient colours from http://bootflat.github.io/color-picker-pink.html //#f58f84
        color: theme.palette.common.white,
        '&:hover': {
            background: 'linear-gradient(135deg, rgba(219,53,74,1) 0%, rgba(245,105,90,1) 100%)'
        },
    }
}));

const ProjectDetails = ({ projectPlans, selectedProjectPlan }) => {

    const classes = useStyles();

    const projectPlan = projectPlans.filter((projectPlan) => projectPlan._id === selectedProjectPlan)[0];

    return (
        <div className={classes.root}>
            <div className={classes.leftColumn}>
                <img className={classes.projectPlanLogo} src={projectPlan.logoUrl} alt={projectPlan.name} />
                {/* <Button className={classes.purchaseButton} startIcon={<ShoppingCart />}>Purchase</Button> */}
            </div>
            <div className={classes.rightColumn}>
                <Typography variant="h5" gutterBottom>{projectPlan.name}</Typography>
                By <Chip 
                    avatar={<Avatar alt={projectPlan.autor} src={projectPlan.authorLogoUrl}  />} 
                    label={projectPlan.author} 
                />
                <div className={classes.summary}>
                    <Typography>{projectPlan.summary}</Typography>
                </div>
                <Table size="small" className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Type:</TableCell>
                            <TableCell><Chip size="small" label={projectPlan.type} /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Categories:</TableCell>
                            <TableCell>
                                {projectPlan.defaultCategories.map((category, i) => {
                                    return <Chip size="small" label={category.name} key={i} className={classes.categoryChip} />
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Tags:</TableCell>
                            <TableCell>
                                {projectPlan.tags.map((tag, i) => {
                                    return <Chip size="small" label={tag.name} key={i} className={classes.tagChip} />
                                })} 
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default ProjectDetails;