import React, { useContext } from 'react';
import Activity from '../activity/Activity.js';
import { List, ListSubheader } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core';
import UserContext from '../../UserContext.js';
import { groupByDate, applyTextFilter } from '../../containers/activities/FilterData.js';
import { DateTime } from "luxon";

const useStyles = makeStyles(theme => ({
    dateGroupHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    week: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    dayColumn: {
        border: "1px solid red",
        flexGrow: 1,
    }
}));

const ActivitiesList = ({ 
    activities, 
    setActivities, 
    students, 
    categories, 
    projects, 
    filterIndex, 
    filters, 
    filterText = "", 
    selectedStudent = 0, 
    sortMethod = "date", 
    setNotificationDetails, 
    showCheckbox = false, 
    checkedActivities = [], 
    handleCheckboxChange = null, 
    hideReorderMenu = false,
    noActivitiesInstructions = ""
}) => {
    const classes = useStyles();
    const { country } = useContext(UserContext);
    const locale = country === 'US' ? 'en-us' : 'en-au';

    let filteredActivities = filters[filterIndex]
    filteredActivities = filteredActivities.sort((a, b) => { //sort by date
        if (sortMethod === "dateCompleted" || sortMethod === "dateCompletedDescending") {
            a = DateTime.fromISO(a.dateCompleted).startOf('day');
            b = DateTime.fromISO(b.dateCompleted).startOf('day');
        } else {
            a = DateTime.fromISO(a.date);
            b = DateTime.fromISO(b.date);
        }
        if (sortMethod === "dateCompletedDescending") {
            return b - a;
        } else {
            return a - b;
        }
    })
    let activitiesByDate = groupByDate(filteredActivities, sortMethod, locale);

    //Apply text filter
    if (filterText.length >= 2) {
        activitiesByDate = applyTextFilter(activitiesByDate, filterText)
    }

    const activitiesWithGrouping = () => {
        const returnData = [];
        activitiesByDate.forEach((group) => {
            returnData.push(<ListSubheader key={group.dateLabel} className={classes.dateGroupHeader}>{group.dateLabel}</ListSubheader>);
            group.activities.forEach((activity, index) => {
                returnData.push(
                    <Activity 
                        key={activity._id} 
                        students={students} 
                        categories={categories} 
                        projects={projects} 
                        data={activity} 
                        activities={activities} 
                        setActivities={setActivities} 
                        filterText={filterText}
                        selectedStudent={selectedStudent}
                        groupActivities={group.activities}
                        activityGroupIndex={index}
                        setNotificationDetails={setNotificationDetails} 
                        showCheckbox={showCheckbox} 
                        checkedActivities={checkedActivities}
                        handleCheckboxChange={handleCheckboxChange}
                        hideReorderMenu={hideReorderMenu}
                    />)
            })
        })

        return (
            <div>
                {activitiesByDate.length === 0 &&
                    <Alert severity="info">No activities match the search criteria{noActivitiesInstructions}</Alert>
                }
                <List subheader={<li />}>
                    {returnData}
                </List>
            </div>
        )
    };

    return activitiesWithGrouping();
};

export default ActivitiesList;