import React from 'react';
import {  Badge, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
    statusSummaryIcon: {
        color: theme.palette.grey[600],
        fontSize: "1.5em",
    },
    statusSummaryIconSmall: {
        color: theme.palette.grey[600],
        fontSize: "1.1em",
        marginRight: "2px"
    },
    statusSummaryIconComplete: {
        color: theme.palette.complete.main,
        fontSize: "1.5em",
    },
    statusIcon: {
        color: theme.palette.grey[600],
    },
    statusIconSmallWrapper: {
        marginRight: "2px"
    },
    statusIconSmall: {
        color: theme.palette.grey[600],
        fontSize: "0.75em"
    },
    statusIconComplete: {
        color: theme.palette.complete.main,
    },
    badge: {
        height: "15px",
        minWidth: "15px",
        padding: 0,
    },
    badgeIcon: {
        color: theme.palette.common.white,
        fontSize: "8px",
    }
}));

const RoutineStatusIcons = ({type}) => {

    const classes = useStyles();

    const scheduledPastMessage = "There are past activities that need to be completed or rescheduled";

    const waitingHeader = () => {
        return (
            <Tooltip title="There are categories without any scheduled activities">
                <Badge 
                    color="secondary" 
                    variant="dot"
                    overlap="rectangular"
                >
                    <FontAwesomeIcon icon="calendar-minus" className={classes.statusSummaryIcon} />
                </Badge>
            </Tooltip>
        );
    };

    const scheduledHeader = () => {
        return (
            <Tooltip title="All categories have scheduled activities">
                <div>
                    <FontAwesomeIcon icon="calendar-check" className={classes.statusSummaryIcon} />
                </div>
            </Tooltip>
        );
    };

    const completedHeader = () => {
        return (
            <Tooltip title="All categories have completed activities">
                <div>
                    <FontAwesomeIcon icon="check-circle" className={classes.statusSummaryIconComplete} />
                </div>
            </Tooltip>
        );
    };

    const scheduledPastHeader = () => {
        return (
            <Tooltip title={scheduledPastMessage}>
                <Badge 
                    color="secondary"
                    overlap="rectangular"
                    classes={{
                        badge: classes.badge
                    }}
                    badgeContent={
                        <FontAwesomeIcon icon="exclamation" className={classes.badgeIcon} />
                    }
                >
                    <FontAwesomeIcon icon="calendar-check" className={classes.statusSummaryIcon} />
                </Badge>
            </Tooltip>
        );
    };

    const partiallyCompleteHeader = () => {
        return (
            <Tooltip title="At least one category has completed activities">
                <div>
                    <FontAwesomeIcon icon="check-circle" className={classes.statusSummaryIcon} />
                </div>
            </Tooltip>
        );
    };

    const skippedHeader = () => {
        return (
            <Tooltip title="All categories have been skipped">
                <div>
                    <FontAwesomeIcon icon="minus" className={classes.statusSummaryIconSmall} />
                </div>
            </Tooltip>
        );
    };

    const waiting = () => {
        return (
            <Tooltip title="Click to add or schedule an activity">
                <div className={classes.statusIconSmallWrapper}>
                    <FontAwesomeIcon icon="plus" className={classes.statusIconSmall} />
                </div>
            </Tooltip>
        );
    };

    const waitingPast = () => {
        return (
            <Tooltip title="This category has been skipped">
                <div className={classes.statusIconSmallWrapper}>
                    <FontAwesomeIcon icon="minus" className={classes.statusIconSmall} />
                </div> 
            </Tooltip>  
        );
    };

    const completed = () => {
        return (
            <Tooltip title="This category has a completed activity">
                <div>
                    <FontAwesomeIcon icon="check-circle" className={classes.statusIconComplete} />
                </div>
            </Tooltip>
        );
    };

    const scheduled = () => {
        return (
            <Tooltip title="This category has a scheduled activity">
                <div>
                    <FontAwesomeIcon icon="calendar-check" className={classes.statusIcon} />
                </div>
            </Tooltip>
        );
    };

    const scheduledPast = () => {
        return (
            <Tooltip title={scheduledPastMessage}>
                <Badge 
                    color="secondary"
                    overlap="rectangular"
                    classes={{
                        badge: classes.badge
                    }}
                    badgeContent={
                        <FontAwesomeIcon icon="exclamation" className={classes.badgeIcon} />
                    }
                >
                    <FontAwesomeIcon icon="calendar-check" className={classes.statusIcon} />
                </Badge>
            </Tooltip>
        );
    };

    switch (type) {
        case "waitingHeader":
            return waitingHeader();
        case "scheduledHeader":
            return scheduledHeader();
        case "completedHeader":
            return completedHeader();
        case "scheduledPastHeader":
            return scheduledPastHeader();
        case "partiallyCompleteHeader":
            return partiallyCompleteHeader();
        case "skippedHeader":
            return skippedHeader();
        case "waiting":
            return waiting();
        case "waitingPast":
            return waitingPast();
        case "completed":
            return completed();
        case "scheduled":
            return scheduled();
        case "scheduledPast":
            return scheduledPast();
        default:
            return null;
    }
}

export default RoutineStatusIcons;