import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import { Dialog, DialogTitle, useTheme, useMediaQuery, IconButton, DialogContent, DialogActions } from '@material-ui/core';
import { ArrowBack } from "@material-ui/icons";
import StyledButton from '../common/StyledButton.js';
import { getProjectPlans, importProjectPlan } from "../../services/projectPlan_services.js";
import { uploadFile } from "../../services/attachment_services.js";
import ProjectDetails from './projectImportWizard/ProjectDetails.js';
import AttachmentUpload from './projectImportWizard/AttachmentUpload.js';
import ProjectSelection from './projectImportWizard/ProjectSelection.js';
import CategoryMapping from './projectImportWizard/CategoryMapping.js';
import StudentSelection from './projectImportWizard/StudentSelection.js';
import DetailsSummary from './projectImportWizard/DetailsSummary.js';
import SimpleDialog from '../common/SimpleDialog.js';
import { logAction } from "../../log.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";
import { FilterList } from '@material-ui/icons';
import { duplicateProjectActivities } from '../../services/project_services.js';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: "90vh",
        maxHeight: "90vh",
    },
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    titleText: {
        flexGrow: 1,
    },
}));

const ProjectImportModal = ({activities, setActivities, students, categories, projects, setProjects, showProjectImportModal, setShowProjectImportModal, setImporting}) => {

    const classes = useStyles();
    const theme = useTheme();
    const screenIsXs = useMediaQuery(theme.breakpoints.down("xs"));
    const [projectPlans, setProjectPlans] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedProjectPlan, setSelectedProjectPlan] = useState("");
    const [categoryMap, setCategoryMap] = useState([]);
    const [categoryMapComplete, setCategoryMapComplete] = useState(false);
    const [studentsToInclude, setStudentsToInclude] = useState([]);
    const [optionParticipation, setOptionParticipation] = useState("together");
    const [studentGroups, setStudentGroups] = useState([]);
    const [showImportCompleteModal, setShowImportCompleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showAlreadyImportedModal, setShowAlreadyImportedModal] = useState(false);
    const [showDuplicateCategoryMessage, setShowDuplicateCategoryMessage] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [projectRequiresAttachments, setProjectRequiresAttachments] = useState(0);
    const [attachmentsForUpload, setAttachmentsForUpload] = useState([]);
    const { country } = useContext(UserContext);
    const location = useLocation();

    useEffect(() => {
        getProjectPlans()
            .then((response) => setProjectPlans(response.data))
            .catch((error) => console.log(error))
    }, [setProjectPlans]);

    useEffect(() => {
        if (selectedProjectPlan) {
            let projectPlanDetails = projectPlans.filter((projectPlan) => projectPlan._id === selectedProjectPlan);
            projectPlanDetails = projectPlanDetails[0];
            if (projectPlanDetails.requiredAttachments.length) {
                setProjectRequiresAttachments(projectPlanDetails.requiredAttachments.length);
            } else {
                setProjectRequiresAttachments(0);
            }
        };
    }, [selectedProjectPlan, projectPlans]);

    useEffect(() => {
        let completeCount = 0
        categoryMap.map((category) => {
            const mapValue = category.userCategory.id
            if (mapValue) {
                completeCount = completeCount + 1;
            };
            if (completeCount === categoryMap.length) {
                setCategoryMapComplete(true);
            }
            return null;
        });
    }, [categoryMap]);

    const hideModal = () => {
        setShowProjectImportModal(false)
        setActiveStep(0)
        setSelectedProjectPlan("")
        setStudentsToInclude([])
        setCategoryMap([])
        setCategoryMapComplete(false)
        setAttachmentsForUpload([])
        setShowDuplicateCategoryMessage(false)
        setOptionParticipation("together")
    };

    useEffect(() => {
        setStudentGroups([studentsToInclude]);
    }, [studentsToInclude]);

    const submitImportRequest = async (selectedProjectPlan, studentGroups, categoryMap) => {
        hideModal();
        setImporting(true);

        const completeImport = (projectObj) => {
            logAction("Successfully imported project", country, location.pathname, projectObj); //Mixpanel logging
            setProjects([...projects, projectObj]);
            setImporting(false);
            hideModal();
            setShowImportCompleteModal(true);
        }

        const promises = attachmentsForUpload.map(async (file) => {
          var s3FileName = await uploadFile(file);
          logAction("Uploaded attachment for project plan", country, location.pathname, { fileType: file.type, fileSize: file.size }); //Mixpanel logging
          return {
            userFileName: file.name,
            fileType: file.type,
            s3FileName: s3FileName
          }
        });
        const projectAttachments = await Promise.all(promises);
        
        const projectDetails = {
            id: selectedProjectPlan,
            students: studentGroups[0],
            categories: categoryMap,
            attachments: projectAttachments
        }

        importProjectPlan(projectDetails)
            .then((responseProject) => {
                let projectObj = responseProject.data.data;
                if (studentGroups.length > 1) {
                    const additionalStudents = studentGroups.slice(1);
                    const dataToDuplicate = {
                        projectId: projectObj._id,
                        studentGroups: additionalStudents
                    };
                    duplicateProjectActivities(dataToDuplicate).then((responseDuplication) => {
                        logAction("Duplicated project activities", country, location.pathname, dataToDuplicate); //Mixpanel logging
                        const newActivities = responseDuplication.data.data;
                        let projectStudents = [];
                        for (let studentGroup of studentGroups) {
                            projectStudents = projectStudents.concat(studentGroup);
                        };
                        projectObj.students = projectStudents;
                        setActivities([...activities, ...newActivities]);
                        completeImport(projectObj);
                    }).catch(error =>{
                        console.log("An error occured during project activity duplication:", error);
                    });
                } else {
                    completeImport(projectObj);
                }
            })
            .catch((error) => {
                setImporting(false);
                setShowErrorModal(true);
                console.log("An error occurred during import: ", error);
            });
    };

    const isNavForwardDisabled = () => {
        switch (activeStep) {
            case 2: 
                if (attachmentsForUpload.length !== projectRequiresAttachments) {
                    return true;
                } else {
                    return false;
                };
            case 4: 
                if (!categoryMapComplete) {
                    return true;
                } else {
                    return false;
                };
            default:
                return false;
        }
    };

    const isNavBackDisabled = () => {
        switch (activeStep) {
            case 0: 
                return true;
            default: 
                return false;
        }
    };

    const navForward = (projectPlanId = "") => {
        switch (activeStep) {
            case 0: 
            setSelectedProjectPlan(projectPlanId);
            logAction("Selected project plan to import", country, location.pathname, { projectPlanId: projectPlanId }); //Mixpanel logging
                //Check if project has already been imported
                const matchingProject = projects.filter((project) => {
                    if (project.hasOwnProperty("projectPlan")) {
                        return project.projectPlan.id === projectPlanId
                    } else {
                        return false;
                    }
                })
                if (matchingProject.length) {
                    setShowAlreadyImportedModal(true);
                } else {
                    setActiveStep(activeStep + 1)
                }
                break                
            case 1: 
                if (projectRequiresAttachments) {
                    setActiveStep(activeStep + 1)
                } else {
                    setActiveStep(activeStep + 2)
                }
                break
            case 2:
                logAction("Provided attachments for project plan import", country, location.pathname, { attachmentsForUpload: attachmentsForUpload }); //Mixpanel logging
                setActiveStep(activeStep + 1)
                break
            case 3: 
                const studentGroupsTemp = studentGroups.filter((studentGroup) => studentGroup.length); //Remove empty arrays
                setStudentGroups(studentGroupsTemp);
                logAction("Selected students for project plan import", country, location.pathname, { studentsToInclude: studentsToInclude }); //Mixpanel logging
                setActiveStep(activeStep + 1)
                break
            case 4:
                let isDuplicateCategory = false;
                for (let c1 in categoryMap) {
                    for (let c2 in categoryMap) {
                        if (c1 !== c2) {
                            if (categoryMap[c1].userCategory.id === categoryMap[c2].userCategory.id) {
                                isDuplicateCategory = true
                                break
                            }
                        }
                    }
                }
                if (isDuplicateCategory) {
                    setShowDuplicateCategoryMessage(true);
                } else {
                    setShowDuplicateCategoryMessage(false);
                    setActiveStep(activeStep + 1);
                    logAction("Completed category mapping", country, location.pathname, { categoryMap: categoryMap }); //Mixpanel logging
                }
                break
            case 5: 
                logAction("Commenced project plan import", country, location.pathname, { selectedProjectPlan: selectedProjectPlan, studentGroups: studentGroups, categoryMap: categoryMap }); //Mixpanel logging
                submitImportRequest(selectedProjectPlan, studentGroups, categoryMap)
                break
            default:
                return false
        }
    };

    const navBack = () => {
        switch (activeStep) {
            case 1: 
                setSelectedProjectPlan("")
                setActiveStep(activeStep - 1)
                break
            case 2: 
                setActiveStep(activeStep - 1)
                break
            case 3: 
                if (projectRequiresAttachments) {
                    setActiveStep(activeStep - 1)
                } else {
                    setActiveStep(activeStep - 2)
                }
                break
            case 4: 
                setShowDuplicateCategoryMessage(false);
                setActiveStep(activeStep - 1)
                break
            case 5: 
                setActiveStep(activeStep - 1)
                break
            default:
                return false
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <ProjectSelection projectPlans={projectPlans} navForward={navForward} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            case 1:
                return <ProjectDetails projectPlans={projectPlans} selectedProjectPlan={selectedProjectPlan} />
            case 2:
                return <AttachmentUpload projectPlans={projectPlans} selectedProjectPlan={selectedProjectPlan} attachmentsForUpload={attachmentsForUpload} setAttachmentsForUpload={setAttachmentsForUpload} />
            case 3:
                return <StudentSelection students={students} studentsToInclude={studentsToInclude} setStudentsToInclude={setStudentsToInclude} optionParticipation={optionParticipation} setOptionParticipation={setOptionParticipation} studentGroups={studentGroups} setStudentGroups={setStudentGroups} />
            case 4:
                return <CategoryMapping categories={categories} projectPlans={projectPlans} selectedProjectPlan={selectedProjectPlan} categoryMap={categoryMap} setCategoryMap={setCategoryMap} showDuplicateCategoryMessage={showDuplicateCategoryMessage} />
            case 5:
                return <DetailsSummary students={students} projectPlans={projectPlans} selectedProjectPlan={selectedProjectPlan} categoryMap={categoryMap} studentGroups={studentGroups} />
            default:
                return 'Unknown step';
        }
    }

    return (
        <Fragment>
            <Dialog fullScreen={screenIsXs ? true : false} fullWidth={true} maxWidth={"lg"} open={showProjectImportModal} classes={!screenIsXs ? { paper: classes.dialogPaper } : null}>
                <DialogTitle>
                    <div className={classes.dialogTitle}>
                        <IconButton
                            onClick={hideModal}
                        >
                            <ArrowBack />
                        </IconButton>
                        <div className={classes.titleText}>Import Project</div>
                        {
                            screenIsXs && !activeStep // Only show on the project selection page
                            ? <IconButton onClick={() => {setDrawerOpen(true)}}>
                                <FilterList />
                            </IconButton>
                            : null
                        }
                    </div>
                </DialogTitle>
                <DialogContent>
                    {getStepContent(activeStep)}
                </DialogContent>
                <DialogActions>
                    {
                        activeStep //Only show these buttons after the project selection page
                        ? <Fragment>
                            <StyledButton onClick={() => hideModal()}>Cancel</StyledButton>
                            <StyledButton disabled={isNavBackDisabled()} onClick={() => navBack()}>Back</StyledButton> 
                            <StyledButton disabled={isNavForwardDisabled()} onClick={() => navForward()}>Next</StyledButton>
                        </Fragment>
                        : null
                    }
                </DialogActions>
            </Dialog>
            <SimpleDialog 
                open={showImportCompleteModal}
                onClose={() => {setShowImportCompleteModal(false)}}
                title="Import Project"
                buttonPrimary={{
                    onClick: () => {setShowImportCompleteModal(false)},
                    label: "OK"
                }}
            >
                Import completed successfully
            </SimpleDialog>
            <SimpleDialog
                open={showErrorModal}
                onClose={() => {setShowErrorModal(false)}}
                title="Import Error"
                buttonPrimary={{
                    onClick: () => {setShowErrorModal(false)},
                    label: "OK"
                }}
            >
                An error occurred and the import could not be completed
            </SimpleDialog>
            <SimpleDialog
                open={showAlreadyImportedModal}
                onClose={() => {setShowAlreadyImportedModal(false)}}
                title="Import Warning"
                buttonPrimary={{
                    onClick: () => {
                        setShowAlreadyImportedModal(false);
                        setActiveStep(activeStep + 1);
                    },
                    label: "OK"
                }}
                buttonSecondary={{
                    onClick: () => {
                        setShowAlreadyImportedModal(false);
                    },
                    label: "Cancel"
                }}
            >
                This project has already been imported. Are you sure you want to import it again?
            </SimpleDialog>
        </Fragment>
    )
}

export default ProjectImportModal;