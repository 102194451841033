import React, { useState, useEffect } from 'react';
import { Checkbox, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderStepper from '../routine/OrderStepper.js';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: "400px"
  },
  categoryContainer: {
    display: "flex"
  },
  categoryIcon: {
    minWidth: theme.spacing(4),
  },
  categoryName: {
    paddingRight: theme.spacing(2)
  },
  checkNumber: {
    fontSize: "0.6em",
    fontWeight: "bold"
  },
  tableCell:  {
    border: "none"
  }, 
  clickable: {
    cursor: "pointer"
  }
}));

const CategorySelectorList = ({ categories, categoriesToInclude, setCategoriesToInclude }) => {

    const classes = useStyles();
    const [totalChecked, setTotalChecked] = useState(0);

    useEffect(() => {
      let totalCheckedTemp = 0;
      categoriesToInclude.map((categoryArray) => {
        totalCheckedTemp = totalCheckedTemp + categoryArray.length;
        return true;
      })
      setTotalChecked(totalCheckedTemp);
    }, [categoriesToInclude, setCategoriesToInclude]);

    const isChecked = (id) => {
      return categoriesToInclude.some((categoryArray) => {
        return categoryArray.includes(id);
      });
    };

    const getParentIndex = (id) => {
      const index = categoriesToInclude.findIndex((categoryArray) => {
          return categoryArray.includes(id);
      })
      return index;
  }

    const handleToggle = (id) => () => {
      let categoriesToIncludeCopy = [...categoriesToInclude]

      const parentIndex = getParentIndex(id);
      let childIndex = -1;

      if (parentIndex >= 0) {
        childIndex = categoriesToInclude[parentIndex].findIndex((c) => {
          return c === id;
        });
        
        categoriesToIncludeCopy[parentIndex].splice(childIndex, 1);
        
        if (!categoriesToIncludeCopy[parentIndex].length) {
          categoriesToIncludeCopy.splice(parentIndex, 1); //If the original array is now empty, remove it
        };
      } else {
        categoriesToIncludeCopy.push([id]);
      }
      
      setCategoriesToInclude(categoriesToIncludeCopy)
    };

    return (
      <Table className={classes.root} size="small" >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>Category</TableCell>
            <TableCell align="center">Order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category, index) => {
              return (
                <TableRow key={index} hover>
                  <TableCell className={`${classes.tableCell} ${classes.clickable}`} onClick={handleToggle(category._id)}>
                    <Checkbox
                      checked={isChecked(category._id)}
                    />
                  </TableCell>
                  <TableCell className={`${classes.tableCell} ${classes.clickable}`} onClick={handleToggle(category._id)}>
                    <div className={classes.categoryContainer}>
                      <div className={classes.categoryIcon}>
                        <FontAwesomeIcon icon={category.icon} style={{ color: category.color }} size="lg" /> 
                      </div>
                      <Typography className={classes.categoryName}>
                        {category.name}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {
                      isChecked(category._id)
                      ? <OrderStepper categoryId={category._id} categoriesToInclude={categoriesToInclude} setCategoriesToInclude={setCategoriesToInclude} totalChecked={totalChecked} getParentIndex={getParentIndex} />
                      : null
                    }
                  </TableCell>
                </TableRow>
              )
          })}
        </TableBody>
      </Table>
    );
}

export default CategorySelectorList;