import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.shade[300],
        boxShadow: "none",
        '&:hover': {
            backgroundColor: theme.palette.primary.shade[400],
            boxShadow: "none"
        },
        '&:disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.shade[100]
        }
    }
}));

const StyledButton = (props) => {

    const classes = useStyles();
    const primary = props.primary ? true : false

    return (
        <Button 
            variant={primary ? "contained" : "outlined"}
            onClick={props.onClick} 
            form={props.form} 
            type={props.type} 
            disabled={props.disabled} 
            startIcon={props.startIcon} 
            className={props.className} 
            color={primary ? "secondary" : "default"}
            size={props.size}
            classes={
                primary
                ? { root: classes.root }
                : props.classes
            }
        >
            {props.children}
        </Button>
    )
}

export default StyledButton;