import React, { useState, useContext } from "react";
import ActivityContext from "./ActivityContext.js";
import { deleteActivity } from "../../services/activity_services.js";
import ActivityModal from "../../components/activity/ActivityModal.js";
import { useLocation } from "react-router-dom";
import { deleteMultipleAttachments } from "../../services/attachment_services.js";
import { logActivityAction } from "../../log.js";
import UserContext from "../../UserContext.js";

const NewActivity = ({ 
    activities, 
    setActivities, 
    isOpen, 
    setShowActivityModal, 
    students, 
    categories, 
    projects, 
    setNotificationDetails, 
    projectId = '', 
    pdfDetails = {}, 
    presetStudents = [],
    presetCategories = [],
    presetDate = null
  }) => {
  const [activityId, setActivityId] = useState("");
  const [details, setDetails] = useState("");
  const [studentsToInclude, setStudentsToInclude] = useState([]);
  const [categoriesToInclude, setCategoriesToInclude] = useState([]);
  const [projectToInclude, setProjectToInclude] = useState("");
  const [activityDate, setActivityDate] = useState(null);
  const [activitySentForReviewDate, setActivitySentForReviewDate] = useState(null);
  const [activityCompleteDate, setActivityCompleteDate] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [comments, setComments] = useState([]);
  const location = useLocation();
  const { country } = useContext(UserContext);

  const activityState = {
    activityId, 
    setActivityId,
    details,
    setDetails,
    studentsToInclude,
    setStudentsToInclude,
    categoriesToInclude,
    setCategoriesToInclude,
    projectToInclude,
    setProjectToInclude,
    activityDate,
    setActivityDate,
    activitySentForReviewDate, 
    setActivitySentForReviewDate,
    activityCompleteDate,
    setActivityCompleteDate,
    attachments,
    setAttachments,
    attachmentLinks,
    setAttachmentLinks,
    comments,
    setComments
  };

  const hideModal = (notificationData = {}) => {
    setDetails("");
    setActivityDate(null);
    setShowActivityModal(false);
    setActivitySentForReviewDate(null);
    setActivityCompleteDate(null);
    setActivityId(null);
    setAttachments([]);
    setAttachmentLinks([]);
    setComments([]);
    if (projectId) { //AB: I'm not sure if this is still necessary, but I'm too scared to remove it
      setProjectToInclude(projectId);
      setStudentsToInclude(presetStudents);
      setCategoriesToInclude(presetCategories);
    } else {
      setProjectToInclude("");
      setStudentsToInclude([]);
      setCategoriesToInclude([]);
    };
    if (notificationData.show) {
      if (notificationData.project) {
        const projectRecord = projects.find((project) => project._id === notificationData.project);
        setNotificationDetails({
          key: Date.now(),
          icon: "add",
          title: "Activity added",
          message: `Activity added to project "${projectRecord.name}"`
        });
      } else {
        setNotificationDetails({
          key: Date.now(),
          icon: "add",
          title: "Activity added",
        });
      }
    }
  };

  const removeActivity = () => {
    const activity = {
      _id: activityId,
      textContent: details,
      date: activityDate,
      dateSentForReview: activitySentForReviewDate,
      dateCompleted: activityCompleteDate,
      students: studentsToInclude,
      categories: categoriesToInclude,
      project: projectToInclude,
      attachments: attachments,
      attachmentLinks: attachmentLinks,
      comments: comments
    };

    if (activity.attachments.length) {
      //If there are attachments, delete them
      deleteMultipleAttachments(
        activity.attachments.map((attachment) => {
          return attachment.s3FileName;
        })
      ).catch((error) => {
        console.log("Error:", error);
      });
    }

    deleteActivity({ deleteId: activity._id })
      .then(() => {
        logActivityAction("Deleted activity", country, location.pathname, activity); //Mixpanel logging
        setActivities(activities.filter((a) => a._id !== activity._id));
        hideModal();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <ActivityContext.Provider value={activityState}>
      {isOpen && //This is to prevent useEffect hooks in ActivityModal from being triggered by component instances that weren't even "open"
        <ActivityModal
          activities={activities}
          setActivities={setActivities}
          isOpen={isOpen} 
          deleteFunction={removeActivity} 
          students={students} 
          categories={categories} 
          projects={projects} 
          location={location} 
          hideModal={hideModal} 
          projectId={projectId} 
          pdfDetails={pdfDetails} 
          presetStudents={presetStudents}
          presetCategories={presetCategories}
          presetDate={presetDate}
          setNotificationDetails={setNotificationDetails}
        />
      }
    </ActivityContext.Provider>
  );
};

export default NewActivity
