import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import { groupByDateInRange } from '../../containers/activities/FilterData.js';
import { DateTime } from "luxon";
import WeekViewColumn from './WeekViewColumn.js';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: "100%",
        overflowX: "auto"
    },
    wrapper: {
        display: "inline-block",
        [theme.breakpoints.up('md')]: {
            width: "100%", 
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    }
}));

const ActivitiesListWeekView = ({ 
    activities, 
    setActivities, 
    students, 
    categories, 
    projects, 
    filterIndex, 
    filters, 
    filterText = "", 
    selectedStudent = 0, 
    setNotificationDetails, 
    calendarDays,
    routineItems, 
    bannerClearance
}) => {
    const classes = useStyles();
    const today = DateTime.local().startOf('day');
    const [studentsExpanded, setStudentsExpanded] = useState([]);
    const [routineItemsObj, setRoutineItemsObj] = useState([]);
    const [columnHeight, setColumnHeight] = useState(0);
    const [activityCount, setActivityCount] = useState(0);
    const [activitiesByDate, setActivitiesByDate] = useState([]);
    const defaultMarginHeight = 145
    const marginHeight = bannerClearance ? defaultMarginHeight + bannerClearance : defaultMarginHeight
    const activityHeight = 132;

    useEffect(() => {
        let filteredActivities = filters[filterIndex]
        setActivityCount(filteredActivities.length)
        filteredActivities = filteredActivities.sort((a, b) => { //sort by date
            a = new Date(a.date);
            b = new Date(b.date);
            return a - b;
        })
        let activitiesByDateTemp = groupByDateInRange(filteredActivities, calendarDays[filterIndex]);
        let maxActivities = 0;
        activitiesByDateTemp.map((group) => {
            if (group.activities.length > maxActivities) {
                maxActivities = group.activities.length;
                return null;
            } else {
                return null;
            }
        });
        setColumnHeight(maxActivities * activityHeight);
        setActivitiesByDate(activitiesByDateTemp);
    }, [filters, filterIndex, calendarDays]);

    useEffect(() => {
        //Build a routineItemsObj that includes routineItems array item for every day of the week
        const routineItemsObjTemp = students.map((student) => {
            let days = [];
            let maxCategories = 0;
            let maxOptionalGroupCount = 0;
            let hasCategories = false;
            const routineItemsForStudent = routineItems.filter((routineItem) => routineItem.student === student._id)
            for (let day = 0; day <= 6; day++) {
                let optionalGroupCount = 0;
                let matchingDay = routineItemsForStudent.filter((routineItem) => routineItem.day === day);
                if (matchingDay.length) {
                    matchingDay = matchingDay[0]
                } else {
                    matchingDay = null;
                }
                let activeCategories = [];
                if (matchingDay) {
                    activeCategories = matchingDay.categories.map((categoryArray) => { //filter out deleted categories 
                        return categoryArray.filter((category) => 
                            categories.some((c) => c._id === category)
                        );
                    }).filter((filteredCategoryArray) => filteredCategoryArray.length > 0); //remove any categoryArray's that are now empty
                };
                if (matchingDay && activeCategories.length) {
                    hasCategories = true;
                    const categoryCount = activeCategories.reduce((sum, categoryArray) => sum + categoryArray.length, 0);
                    if (categoryCount > maxCategories) {
                        maxCategories = categoryCount;
                    }
                    activeCategories.map((categoryArray) => {
                        if (categoryArray.length > 1) {
                            optionalGroupCount = optionalGroupCount + 1;
                        };
                        return true;
                    });
                    if (optionalGroupCount > maxOptionalGroupCount) {
                        maxOptionalGroupCount = optionalGroupCount;
                    };
                    days.push(matchingDay);
                } else {
                    days.push({day: day})
                }
            }
            return {
                studentId: student._id,
                hasCategories: hasCategories,
                maxCategories: maxCategories,
                maxOptionalGroupCount: maxOptionalGroupCount,
                routineItems: days
            }
        })
        setRoutineItemsObj(routineItemsObjTemp);
    }, [students, categories, routineItems]);

    const weekColumns = activitiesByDate.map((group, i) => {
        const date = group.date;
        const dateDiff = date - today;
        const weekday = group.weekday - 1;
        const routineItemsForDay = routineItemsObj.map((student) => {
            const itemsForDay = student.routineItems.filter((item) => item.day === weekday)
            return {
                studentId: student.studentId,
                hasCategories: student.hasCategories,
                maxCategories: student.maxCategories,
                maxOptionalGroupCount: student.maxOptionalGroupCount,
                routineItems: itemsForDay
            };
        });

        return <WeekViewColumn
            key={i}
            group={group}
            activities={activities}
            setActivities={setActivities}
            students={students}
            categories={categories}
            projects={projects}
            date={date}
            dateDiff={dateDiff}
            filterText={filterText}
            selectedStudent={selectedStudent}
            setNotificationDetails={setNotificationDetails}
            studentsExpanded={studentsExpanded}
            setStudentsExpanded={setStudentsExpanded}
            routineItemsForDay={routineItemsForDay}
            columnHeight={columnHeight}
        />
    });
    
    return (
        <div className={classes.root} style={{marginTop: marginHeight}}>
            <div className={classes.wrapper}>
                <div className={classes.row}>
                    {weekColumns}
                </div>
                {
                    activityCount
                    ? null
                    : <Alert severity="info" className={classes.alert}>There are currently no activities scheduled</Alert>
                 }
            </div>
        </div>
    )
}

export default ActivitiesListWeekView;