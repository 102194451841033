import React, {useState} from 'react';
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Avatar, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import StyledButton from "../common/StyledButton.js";
import { Alert } from "@material-ui/lab";
import NewRoutineItem from './NewRoutineItem.js';
import EditRoutineItem from './EditRoutineItem.js';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        verticalAlign: "top"
    },
}));

const Routine = ({showRoutine, setShowRoutine, students, categories, routineItems, setRoutineItems}) => {

    const classes = useStyles();
    const [selectedStudent, setSelectedStudent] = useState("");
    const [selectedDay, setSelectedDay] = useState(null);
    const [categoriesToInclude, setCategoriesToInclude] = useState([]);

    const days = [
        "MON",
        "TUE",
        "WED",
        "THU",
        "FRI",
        "SAT",
        "SUN"
    ]

    const hideModal = () => {
        setShowRoutine(false);
    }

    return (
        <Dialog open={showRoutine} onClose={hideModal} fullWidth={true} maxWidth={"lg"}>
            <DialogTitle>
                Routine
            </DialogTitle>
            <DialogContent>
                {
                    students.length
                    ? <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {days.map((day, i) => {
                                        return <TableCell key={i}>{day}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {students.map((student, i) => {
                                        const studentInitial = student.name.substring(0, 1).toUpperCase();
                                        return (
                                            <TableRow key={i}>
                                                <TableCell className={classes.tableCell}>
                                                    <Tooltip title={student.name}>
                                                        <Avatar 
                                                            style={{ backgroundColor: `${student.color}` }}
                                                        >
                                                            {studentInitial}
                                                        </Avatar>
                                                    </Tooltip>
                                                </TableCell>
                                                {days.map((day, dayIndex) => {
                                                    let hasMatchingRoutineItem = false;
                                                    let matchingRoutineItem = {};
                                                    const matchingRoutineItems = routineItems.filter((routineItem) => routineItem.student === student._id && routineItem.day === dayIndex);
                                                    if (matchingRoutineItems.length) {
                                                        hasMatchingRoutineItem = true;
                                                        matchingRoutineItem = matchingRoutineItems[0]; //There should only ever be 1 per student per day
                                                        const activeCategories = matchingRoutineItem.categories.map((categoryArray) => { //filter out deleted categories 
                                                            return categoryArray.filter((category) => 
                                                                categories.some((c) => c._id === category)
                                                            );
                                                        }).filter((filteredCategoryArray) => filteredCategoryArray.length > 0); //remove any categoryArray's that are now empty
                                                        matchingRoutineItem.categories = activeCategories;
                                                    };
                                                    return (
                                                        hasMatchingRoutineItem //Even if all categories have been filtered out, we want to "edit" so we can update the existing routineItem record
                                                        ? <EditRoutineItem 
                                                            key={student._id + dayIndex} 
                                                            studentId={student._id} 
                                                            dayIndex={dayIndex} 
                                                            routineItems={routineItems}
                                                            matchingRoutineItem={matchingRoutineItem} 
                                                            setRoutineItems={setRoutineItems}
                                                            selectedStudent={selectedStudent}
                                                            setSelectedStudent={setSelectedStudent}
                                                            selectedDay={selectedDay}
                                                            setSelectedDay={setSelectedDay}
                                                            categoriesToInclude={categoriesToInclude}
                                                            setCategoriesToInclude={setCategoriesToInclude}
                                                            students={students}
                                                            categories={categories}
                                                        />
                                                        : <NewRoutineItem 
                                                            key={student._id + dayIndex} 
                                                            studentId={student._id} 
                                                            dayIndex={dayIndex} 
                                                            routineItems={routineItems}
                                                            setRoutineItems={setRoutineItems}
                                                            selectedStudent={selectedStudent}
                                                            setSelectedStudent={setSelectedStudent}
                                                            selectedDay={selectedDay}
                                                            setSelectedDay={setSelectedDay}
                                                            categoriesToInclude={categoriesToInclude}
                                                            setCategoriesToInclude={setCategoriesToInclude}
                                                            students={students}
                                                            categories={categories}
                                                        />
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <Alert severity="info">Add a student</Alert>
                }
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={() => {hideModal()}}>
                    Done
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
}

export default Routine;