import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.spacing(12),
    },
    input: {
        textAlign: "center",
        color: theme.palette.text.primary,
        padding: 0
    },
    underline: {
        "&.Mui-disabled::before": {
            border: "none"
        },
        "&::before": {
            border: "none"
        }
    },
    adornedStart: {
        paddingLeft: 0
    },
    adornedEnd: {
        paddingRight: 0
    },
    iconEnabled: {
        color: theme.palette.grey[500],
    },
    iconDisabled: {
        color: theme.palette.grey[200],
    }
}));

const OrderStepper = ({categoryId, categoriesToInclude, setCategoriesToInclude, totalChecked, getParentIndex}) => {
    
    const classes = useStyles();
    const [parentIndex, setParentIndex] = useState(0);

    useEffect(() => {
        const parentIndexTemp = getParentIndex(categoryId);
        setParentIndex(parentIndexTemp);
    }, [categoryId, categoriesToInclude, setCategoriesToInclude, getParentIndex]);

    const incrementOrder = () => {
        let categoriesToIncludeCopy = [...categoriesToInclude];
        const childIndex = categoriesToIncludeCopy[parentIndex].findIndex((c) => {
            return c === categoryId ;
        });

        categoriesToIncludeCopy[parentIndex].splice(childIndex, 1); //Remove from current child array

        if (parentIndex < categoriesToInclude.length - 1) { 
            categoriesToIncludeCopy[parentIndex + 1].push(categoryId); //Add to an existing child array
        } else { 
            categoriesToIncludeCopy.push([categoryId]); //Create new child array
        };

        if (!categoriesToIncludeCopy[parentIndex].length) {
            categoriesToIncludeCopy.splice(parentIndex, 1); //If the original array is now empty, remove it
        };

        setCategoriesToInclude(categoriesToIncludeCopy); 
    };
  
    const decrementOrder = () => {
        let categoriesToIncludeCopy = [...categoriesToInclude];      
        const childIndex = categoriesToIncludeCopy[parentIndex].findIndex((c) => {
            return c === categoryId ;
        });

        if (parentIndex > 0) { 
            categoriesToIncludeCopy[parentIndex].splice(childIndex, 1); //Remove from current child array
            categoriesToIncludeCopy[parentIndex - 1].push(categoryId); //Add to an existing child array
            if (!categoriesToIncludeCopy[parentIndex].length) {
                categoriesToIncludeCopy.splice(parentIndex, 1); //If the original array is now empty, remove it
            };
            setCategoriesToInclude(categoriesToIncludeCopy);
        };
    };

    return (
        <TextField
            disabled={true}
            className={classes.root}
            value={parentIndex + 1}
            InputProps={{
                classes: {
                    underline: classes.underline,
                },
            inputProps: {
                className: classes.input,
            },
            startAdornment: <InputAdornment position="end">
                <IconButton 
                    size="small" 
                    onClick={decrementOrder}
                    disabled={parentIndex === 0}
                >
                    <RemoveCircle className={parentIndex === 0 ? classes.iconDisabled : classes.iconEnabled} />
                </IconButton>
            </InputAdornment>,
            endAdornment: <InputAdornment position="start">
                <IconButton 
                    size="small" 
                    onClick={incrementOrder}
                    disabled={parentIndex + 1 === totalChecked}
                >
                    <AddCircle className={parentIndex + 1 === totalChecked ? classes.iconDisabled : classes.iconEnabled} />
                </IconButton>
            </InputAdornment>
            }}
        />
    )
};

export default OrderStepper;