import React, { useState, Fragment, useContext } from 'react';
import { TableCell, Chip } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core";
import RoutineItemModal from "./RoutineItemModal.js";
import { logAction } from "../../log.js";
import UserContext from "../../UserContext.js";
import { useLocation } from "react-router-dom";
import { updateRoutineItem, deleteRoutineItem } from '../../services/routine_services.js';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    tableCell: {
        cursor: "pointer",
        padding: theme.spacing(0.5),
        verticalAlign: "top"
    },
    categoryChip: {
        margin: theme.spacing(0.5),
        cursor: "pointer",
    },
    addIconWrapper: {
        padding: theme.spacing(1.5),
    },
    addIcon: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        color: theme.palette.grey[600]
    },
}));

const EditRoutineItem = ({studentId, dayIndex, routineItems, matchingRoutineItem, setRoutineItems, selectedStudent, setSelectedStudent, selectedDay, setSelectedDay, categoriesToInclude, setCategoriesToInclude, students, categories }) => {

    const classes = useStyles();
    const location = useLocation();
    const { country } = useContext(UserContext);
    const [showRoutineItemModal, setShowRoutineItemModal] = useState(false);
    const [id, setId] = useState(null);

    const routineItemCategories = matchingRoutineItem.categories.map((categoryArray) => {
        return categoryArray.map((category) => {
            const categoryRecord = categories.find((c) => c._id === category);
            if (categoryRecord) {
                return categoryRecord;
            } else {
                return false;
            }
        });
    });

    const handleClick = (studentId, dayIndex, matchingRoutineItem) => {
        setId(matchingRoutineItem._id);
        setCategoriesToInclude(matchingRoutineItem.categories);
        setSelectedStudent(studentId);
        setSelectedDay(dayIndex);
        setShowRoutineItemModal(true);
    }

    const hideModal = () => {
        setId(null);
        setShowRoutineItemModal(false);
        setSelectedStudent("");
        setSelectedDay(null);
        setCategoriesToInclude([]);
    }

    const submitRoutineItem = (e) => {
        e.preventDefault();
        const data = {
            _id: id,
            student: selectedStudent,
            day: selectedDay,
            categories: categoriesToInclude,
            isLoop: false,
            loopName: "",
        };
        if (categoriesToInclude.length) {
            updateRoutineItem(data).then((response) => {
                logAction("Updated routine item", country, location.pathname, response.data.data); //Mixpanel logging
                let routineItemIndex = routineItems.findIndex(routineItem => routineItem._id === id);
                let routineItemsCopy = [...routineItems];
                routineItemsCopy[routineItemIndex].student = selectedStudent;
                routineItemsCopy[routineItemIndex].day = selectedDay;
                routineItemsCopy[routineItemIndex].categories = categoriesToInclude;
                setRoutineItems([...routineItemsCopy]);
                //document.getElementById("form").reset()
            }).catch(error =>{
                console.log("An error occured during routine item submission:", error);
            });
        } else {
            deleteRoutineItem({ deleteId: id })
            .then((response) => {
              logAction("Deleted routine item", country, location.pathname, data); //Mixpanel logging
              setRoutineItems(routineItems.filter((ri) => ri._id !== id));
            })
            .catch((error) => {
              console.log("An error occurred trying to delete routine item ", id, ": ", error);
            });
        }
        hideModal();
    };
    return (
        <Fragment>
            <TableCell
                className={classes.tableCell}
                onClick={() => {handleClick(studentId, dayIndex, matchingRoutineItem)}}
            >
                {matchingRoutineItem.categories.length
                ? routineItemCategories.map((categoryArray, c) => {
                        return (
                            <div key={c}>
                                {categoryArray.map((categoryRecord, i) => {
                                    return (
                                        <div key={i}>
                                            {
                                                categoryArray.length > 1
                                                && i > 0
                                                && " OR "
                                            }
                                            <Chip  
                                                icon={<FontAwesomeIcon icon={categoryRecord.icon} style={{ color: categoryRecord.color }} size="lg" /> } 
                                                label={categoryRecord.name} 
                                                variant="outlined"
                                                className={classes.categoryChip}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    })
                : <div className={classes.addIconWrapper}><Add className={classes.addIcon} /></div> //This will occur if the routine item only has categories that have been deleted
                }
            </TableCell>
            <RoutineItemModal 
                showRoutineItemModal={showRoutineItemModal} 
                selectedStudent={selectedStudent}
                selectedDay={selectedDay}
                categoriesToInclude={categoriesToInclude}
                setCategoriesToInclude={setCategoriesToInclude}
                students={students}
                categories={categories}
                cancelFunction={hideModal}
                submitFunction={submitRoutineItem}
            />
        </Fragment>
    )
}

export default EditRoutineItem;