import React, {Fragment, useState, useEffect} from 'react';
import { Button, Typography, Collapse, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardArrowDown } from '@material-ui/icons';
import { KeyboardArrowUp } from '@material-ui/icons';
import FindActivitiesModal from '../activities/FindActivitiesModal.js';
import RoutineStatusIcon from './RoutineStatusIcon.js'
import NewActivity from '../../containers/activities/NewActivity.js';
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    studentRowHeader: {
        display: "flex",
        flexDirection: "row",
        height: theme.spacing(6),
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(1),
        alignItems: "center",
        cursor: "pointer",
    },
    statusSummary: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
        paddingRight: theme.spacing(0.5),
    },
    studentRowBody: {
        padding: theme.spacing(1),
    },
    categoryButton: {
        marginBottom: theme.spacing(1),
        width: "100%",
        height: theme.spacing(6)
    },
    categoryButtonComplete: {
        backgroundColor: theme.palette.complete.shade[50],
        '&:hover': {
            backgroundColor: theme.palette.complete.shade[50]
        },
    },
    categoryButtonLabel: {
        width: "100%",
        textTransform: "none",
        textAlign: "left"
    },
    avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        fontSize: "1em",
        marginRight: theme.spacing(1)
    },
    optionalGroup: {
        borderStyle: "dashed",
        borderWidth: theme.spacing(0.25),
        borderColor: theme.palette.grey[500],
        marginBottom: theme.spacing(1),
    },
    optionalGroupCompleted: {
        backgroundColor: theme.palette.complete.shade[50],
    }
}));

const RoutineStatus = ({activities, setActivities, students, categories, projects, setNotificationDetails, student, routineItemsForDay, dateHeader, studentsExpanded, expandFunction, routineStatusData, studentRoutineItems, maxCategories, maxOptionalGroupCount}) => {

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [studentRoutineItemsFiltered, setStudentRoutineItemsFiltered] = useState([]);
    const [categoryStatus, setCategoryStatus] = useState([]);
    const [showFindActivitiesModal, setShowFindActivitiesModal] = useState(false);
    const [showNewActivityModal, setShowActivityModal] = useState(false);
    const [categoryId, setCategoryId] = useState("");
    const [checkedActivities, setCheckedActivities] = useState([]);
    const [rowHeight, setRowHeight] = useState(0);

    const today = DateTime.local().startOf('day');
    const dateDiff = dateHeader - today;
    const isPast = dateDiff < 0 ? true : false;
    const singleRowHeight = 55;
    const extraPadding = 8;
    const optionalGroupBorder = 12;

    useEffect(() => {
        const studentRoutineItemsFilteredTemp = studentRoutineItems.filter((routineItem) => { 
            if (!routineItem.isLoop) {
                if (routineItem.hasOwnProperty("categories")) {
                    const activeCategories = routineItem.categories.map((categoryArray) => { //filter out deleted categories 
                        return categoryArray.filter((category) => 
                            categories.some((c) => c._id === category)
                        );
                    }).filter((filteredCategoryArray) => filteredCategoryArray.length > 0); //remove any categoryArray's that are now empty
                    if (activeCategories.length) {
                        routineItem.categories = activeCategories;
                        return routineItem;
                    } else {
                        return null;
                    };
                } else {
                    return routineItem;
                }
            } else {
                return null;
            };
        });
        setStudentRoutineItemsFiltered(studentRoutineItemsFilteredTemp);
        const optionalGroupAllowance = maxOptionalGroupCount * optionalGroupBorder;
        setRowHeight(maxCategories * singleRowHeight + extraPadding + optionalGroupAllowance);
    }, [routineItemsForDay, activities, categories, dateHeader, student, maxCategories, maxOptionalGroupCount, studentRoutineItems]);

    useEffect(() => {
        const categoryStatusNew = studentRoutineItemsFiltered.map((routineItem, i) => {
            if (routineItem.hasOwnProperty("categories")) {
                let categoryRecords = routineItem.categories.map((categoryArray) => {
                    return categoryArray.map((category) => {
                        const categoryRecord = categories.find((c) => c._id === category);
                        if (categoryRecord) {
                            return categoryRecord;
                        } else {
                            return false;
                        }
                    });
                });
                return categoryRecords.map((categoryArray) => {
                    return categoryArray.map((category) => {
                        let status = "waiting";
                        for (let [categoryId, categoryData] of Object.entries(routineStatusData)) {
                            if (categoryId === category._id) {
                                if (categoryData.completed) {
                                    status = "completed"
                                } else if (categoryData.scheduled) {
                                    status = "scheduled"
                                }
                            }
                        };
                        return {
                            category: category._id,
                            status: status
                        };
                    });
                })
            } else {
                return [];
            }
        })
        setCategoryStatus(categoryStatusNew)
    }, [studentRoutineItemsFiltered, routineStatusData, categories]);

    useEffect(() => {
        const index = studentsExpanded.findIndex((studentExpanded) => studentExpanded.id === student._id)
        if (typeof studentsExpanded[index] !== 'undefined') {
            setExpanded(studentsExpanded[index].expanded);
        };
    }, [studentsExpanded, student]);

    const handleClick = (categoryId) => {
        setCategoryId(categoryId);
        setShowFindActivitiesModal(true);
    }

    const hideModal = (resetCategoryId = true) => {
        if (resetCategoryId) {
            setCategoryId("");
        };
        setCheckedActivities([])
        setShowFindActivitiesModal(false);
    }

    return (
        <Fragment>
            <div>
                <div className={classes.studentRowHeader} onClick={() => {expandFunction(student._id)}}>
                    {dateHeader.weekday === 1 &&
                        <Fragment>
                            <IconButton>
                                {!expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                            </IconButton>
                            <Typography>{student.name}</Typography>
                        </Fragment>
                    }
                    <div className={classes.statusSummary}>
                        {categoryStatus.map((categoryStatusItem, i) => { 
                            // For each category group (i.e. group of "optional" categories) we need to determine an overall status for that group
                            // If any in the group are "scheduled", the overall status is "scheduled"
                            // If none of them are "scheduled" and there is at least one "completed", the overall status is "completed"
                            // If none of them are "scheduled" or "completed", the overall status is "waiting"
                            const statuses = categoryStatusItem.map((categoryGroup) => {
                                let categoryGroupStatus = "";
                                categoryGroup.map((category) => {
                                    if (category.hasOwnProperty("status")) {
                                        switch (category.status) {
                                            case "scheduled":
                                                categoryGroupStatus = category.status;
                                                break;
                                            case "completed":
                                                if (categoryGroupStatus !== "scheduled") {
                                                    categoryGroupStatus = category.status;
                                                };
                                                break;
                                            case "waiting":
                                                if (!categoryGroupStatus) {
                                                    categoryGroupStatus = category.status;
                                                };
                                                break;
                                            default:
                                                return true;
                                        };
                                    }
                                    return true;
                                })
                                return categoryGroupStatus;
                            })
                            if (statuses.length) {
                                if (!isPast) {
                                    if (statuses.includes("waiting")) { // if there are ANY waiting
                                        return <RoutineStatusIcon key={i} type="waitingHeader" />;
                                    } else if (statuses.includes("scheduled")) { // if there are NO waiting, but there are scheduled
                                        return <RoutineStatusIcon key={i} type="scheduledHeader" />;
                                    } else if (statuses.includes("completed")) { // if there are only completed
                                        return <RoutineStatusIcon key={i} type="completedHeader" />;
                                    } else {
                                        return null;
                                    }
                                } else {
                                    if (statuses.includes("scheduled")) { // if there are ANY scheduled
                                        return <RoutineStatusIcon key={i} type="scheduledPastHeader" />;
                                    } else if (statuses.includes("waiting") && statuses.includes("completed")) { // if there are NO scheduled, but there are waiting and completed
                                        return <RoutineStatusIcon key={i} type="partiallyCompleteHeader" />;
                                    } else if (statuses.includes("waiting")) { // if there are only waiting
                                        return <RoutineStatusIcon key={i} type="skippedHeader" />;
                                    } else if (statuses.includes("completed")) { // if there are only completed
                                        return <RoutineStatusIcon key={i} type="completedHeader" />;
                                    } else {
                                        return null;
                                    }
                                }
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
                {studentRoutineItemsFiltered.map((routineItem, i) => {
                    return (
                        <Collapse key={i} in={expanded}>
                            <div className={classes.studentRowBody} style={{height: rowHeight}}>
                                {categoryStatus.map((categoryArray) => {
                                    return categoryArray.map((categoryStatusArray, i) => {
                                        const isOptionalGroup = categoryStatusArray.length > 1 ? true : false;
                                        let optionalGroupIsCompleted = false;
                                        if (isOptionalGroup) {
                                            optionalGroupIsCompleted = categoryStatusArray.some(category => category.status === "completed");
                                        };
                                        return (
                                            <div key={i} className={`${isOptionalGroup ? classes.optionalGroup : null} ${optionalGroupIsCompleted && classes.optionalGroupCompleted}`}>
                                                {categoryStatusArray.map((categoryStatusItem) => {
                                                    const categoryRecord = categories.find((c) => c._id === categoryStatusItem.category);
                                                    if (categoryRecord) {
                                                        let statusIcon = null;
                                                        switch (categoryStatusItem.status) {
                                                            case "waiting":
                                                                statusIcon = 
                                                                    !isPast
                                                                    ? <RoutineStatusIcon key={i} type="waiting" />
                                                                    : <RoutineStatusIcon key={i} type="waitingPast" />
                                                                ;
                                                                break;
                                                            case "completed": 
                                                                statusIcon = <RoutineStatusIcon key={i} type="completed" />;
                                                                break;
                                                            case "scheduled":
                                                                statusIcon = 
                                                                    !isPast
                                                                    ? <RoutineStatusIcon key={i} type="scheduled" />
                                                                    : <RoutineStatusIcon key={i} type="scheduledPast" />
                                                                ;
                                                                break;
                                                            default:
                                                                statusIcon = null;
                                                        }
                                                        return (
                                                            <Button 
                                                                key={routineItem._id + categoryStatusItem.category}
                                                                onClick={() => {handleClick(categoryRecord._id)}}
                                                                startIcon={<FontAwesomeIcon icon={categoryRecord.icon} style={{ color: categoryRecord.color }} size="lg" /> } 
                                                                endIcon={statusIcon}
                                                                className={`${classes.categoryButton} ${categoryStatusItem.status === "completed" && classes.categoryButtonComplete}`}
                                                            >
                                                                <Typography variant="body2" className={classes.categoryButtonLabel}>
                                                                    {categoryRecord.name}
                                                                </Typography>
                                                            </Button>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        )
                                    })
                                })}
                            </div>
                        </Collapse>
                    )}
                )}
            </div>
            <FindActivitiesModal
                activities={activities}
                setActivities={setActivities}
                students={students}
                categories={categories}
                projects={projects}
                checkedActivities={checkedActivities}
                setCheckedActivities={setCheckedActivities}
                setNotificationDetails={setNotificationDetails}
                setShowActivityModal={setShowActivityModal}
                open={showFindActivitiesModal}
                closeFunction={hideModal}
                studentId={student._id}
                categoryId={categoryId}
                date={dateHeader}
            />
            <NewActivity
                setActivities={setActivities}
                activities={activities}
                isOpen={showNewActivityModal}
                setShowActivityModal={setShowActivityModal}
                students={students}
                categories={categories} 
                projects={projects}
                presetStudents={[student._id]}
                presetCategories={[categoryId]}
                presetDate={dateHeader}
                setNotificationDetails={setNotificationDetails}
            />
        </Fragment>
    )
}

export default RoutineStatus;